import Loader from "@components/Loader";
import Overlay from "./Overlay";
import CloseBtn from "./CloseButton";

interface Props {
  children?: React.ReactNode;
  title: string;
  header?: string;
  isLoading?: boolean;
  onClose: () => void;
}

const OverlayModal = (props: Props) => {
  const { children, title, isLoading, header, onClose } = props;
  // const { appModal, setAppModal } = useContext(ContextStore);
  // const { successToast, errorToast } = useDisplayToast();

  return (
    <Overlay onDone={onClose} zIndex={"z-[2001]"}>
      <div
        className={`-sm:w-fit -lg:w-[600px] lg:w-[900px] h-max max-w-[900px] py-10 flex flex-col flex-1 rounded-lg mx-8`}
      >
        <div
          className={`px-5 h-14 bg-primary text-white flex flex-shrink-0 ${
            header ? "justify-between" : "justify-end "
          } items-center rounded-t-lg`}
        >
          {header && (
            <p className="text-2xl font-semibold -sm:text-lg">{header}</p>
          )}
          {<CloseBtn variant="outline" closeModal={onClose} />}
        </div>

        {isLoading ? (
          <Loader size={"xl"} />
        ) : (
          <div className="px-5 pb-16 overflow-y-scroll custom-scrollbar text-sm font-bold bg-white relative overflow-hidden">
            {title && (
              <p className="text-2xl font-semibold -sm:text-lg pb-5 pt-5 text-center">
                {title}
              </p>
            )}
            {children}
          </div>
        )}
      </div>
    </Overlay>
  );
};

export default OverlayModal;
