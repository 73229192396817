import { useDisclosure } from "@chakra-ui/react";

const Faqs = () => {
  const faqs = [
    {
      text: "How do I generate action items from meeting notes?",
      details:
        "You can either manually enter your meeting notes or upload a transcript, and the app uses smart analysis to identify key tasks, creating a clear list of action items for you, that you easily edit.",
    },
    {
      text: "Can I manually update progress on tasks?",
      details:
        'Yes, absolutely! Simply click each action item as "Done," and watch the progress bar automatically fill up to reflect your progress.',
    },
    {
      text: "What happens when all action items are completed?",
      details:
        "When all action items are marked as complete, the progress bar will reach 100%, indicating you're all set! You’ll then have the option to either review your completed items or decide if the follow-up meeting is necessary.",
    },
    {
      text: "Is there a free trial period?",
      details:
        "Yes! You can enjoy a free 7-day trial period to explore the app’s features and see how it enhances your productivity. During this time, you'll have full access to create and track action items and update progress. At the end of your trial, you’ll have the choice to select a plan that best suits your needs and continue maximizing your productivity!",
    },
  ];

  return (
    <div
      id="faq"
      className="max-w-[991px] mx-auto py-24 sm:py-[145px] px-10 sm:px-14"
    >
      <p className="text-3xl sm:text-4xl lg:text-5xl text-[#475467] text-center font-bold uppercase">
        Frequently Asked Questions
      </p>
      <p className="mt-5 mb-9 text-base sm:text-lg text-[#98a2b3] -sm:leading-snug text-center">
        Whether you’re curious about how the app works, how to track progress,
        or what happens after tasks are completed, our FAQs cover all you need
        to know.
      </p>

      <div className="mt-14 flex flex-col gap-5">
        {faqs?.map((faq, index) => (
          <Disclosure key={index} faq={faq} />
        ))}
      </div>
    </div>
  );
};

export default Faqs;

const Disclosure = ({ faq }: { faq: { text: string; details: string } }) => {
  const { getDisclosureProps, getButtonProps, isOpen } = useDisclosure();

  const buttonProps = getButtonProps();
  const disclosureProps = getDisclosureProps();

  return (
    <div
      className={`px-5 sm:px-8 py-6 sm:py-10 lg:py-12 rounded-[18px] shadow-md border-2 group ${
        isOpen
          ? " border-[#2563eb]"
          : "border-transparent hover:border-[#2563eb]"
      }`}
    >
      <div
        className="w-full flex justify-between gap-3 text-[#111827] cursor-pointer"
        {...buttonProps}
      >
        <span className="text-xl sm:text-[22px] md:text-xl font-semibold text-left">
          {faq.text}
        </span>
        <svg
          className={`w-7 -sm:w-6 h-7 -sm:h-6 mt-1 transition-transform ${
            isOpen
              ? "bg-primary-dark rotate-90 border-[#2563eb]"
              : "rotate-0 border-transparent group-hover:border-[#2563eb]"
          } flex-shrink-0 rounded-full shadow-lg border`}
          width="31"
          height="32"
          viewBox="0 0 31 32"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="chevron-right">
            <path
              id="Icon"
              d="M11.5234 23.5148L19.1031 15.9352L11.5234 8.35547"
              style={{
                stroke: "currentColor",
                strokeWidth: "2",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                fill: "none",
              }}
            />
          </g>
        </svg>
      </div>
      <div
        {...disclosureProps}
        className="mt-4 pb-4 text-base sm:text-lg text-[#4B5563]"
      >
        {faq.details}
      </div>
    </div>
  );
};
