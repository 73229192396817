import React from "react";

interface BannerProps {
  children?: React.ReactNode;
  text?: string | React.ReactNode;
  showOffer?: React.ReactNode;
}

const Banner = (props: BannerProps) => {
  const { showOffer, text } = props;
  return (
    <div
      className={`py-3 -sm:py-2 flex-center gap-x-2 gap-y-1 -sm:flex-col text-white bg-gradient`}
    >
      {showOffer && (
        <div className=" h-max mr-2 px-2 py-1 text-xs font-semibold bg-[#f2f4f791] rounded-lg border border-[#E9D8FD]">
          <span>Limited offer</span>
        </div>
      )}
      <div
        className={`-sm:p-3 -sm:text-wrap text-sm sm:text-base md:text-lg text-center`}
      >
        {text ? (
          text
        ) : (
          <>
            Join early! And get your subscription at a{" "}
            <span className="font-bold">30% discount</span> for a lifetime!
          </>
        )}
      </div>
    </div>
  );
};

export default Banner;
