// import logo from "@assets/logo.svg";
import { BsTwitter } from "react-icons/bs/index";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa/index";
import { BsYoutube } from "react-icons/bs/index";
import { appName, baseUrl } from "@utils/index";
import { Link } from "react-router-dom";
import { AppLogo } from "./Navbar";

function Footer() {
  return (
    <footer className="pb-8 bg-white max-w-[1244px] mx-auto">
      <div className="px-10 sm:px-14 py-12 sm:py-16 border-b border-[#D4D2E3] flex flex-col lg:flex-row gap-10 justify-between lg:items-center">
        <div className="flex justify-start flex-wrap">
          <AppLogo />
        </div>
        <nav className="">
          <ul className="w-full flex items-center gap-5 sm:gap-10">
            <li>
              <a href={`${baseUrl.landing}/`}>Home</a>
            </li>
            <li>
              <a href={`${baseUrl.landing}/#features`}>Features</a>
            </li>
            {/* <li>
              <a href={`${baseUrl.landing}/#pricing`}>Pricing</a>
            </li>
            <li>
              <a href={`${baseUrl.landing}/#faq`}>FAQ</a>
            </li> */}
            <li>
              <a href={`${baseUrl.landing}/#why`}>Why</a>
            </li>
            <li>
              <Link to={`${baseUrl.landing}/contact`}>Contact</Link>
            </li>
          </ul>
        </nav>
        <div className="flex gap-4">
          <a
            href={`//facebook.com/beforewemeet`}
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
          <span className="text-gray-300 ml-[10rem]"></span>

          {/* <a
            href={`//twitter.com/beforewemeet`}
            target="_blank"
            rel="noreferrer"
          >
            <BsTwitter />
          </a>
          <a
            href={`//www.instagram.com/beforewemeet`}
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href={`//linkedin.com/company/beforewemeet`}
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn />
          </a>
          <a
            href={`//youtube.com/@beforewemeet`}
            target="_blank"
            rel="noreferrer"
          >
            <BsYoutube />
          </a> */}
        </div>
      </div>
      <p className="px-5 mt-5 text-center text-sm text-gray-500">
        Copyright &copy; {new Date().getFullYear()} Beforewemeet | All rights
        reserved.
      </p>
    </footer>
  );
}

export default Footer;
