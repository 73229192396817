import trackIcon from "@assets/track-icon.svg";
import progressIcon from "../../../assets/progress-icon.jpg";
import timeIcon from "@assets/time-icon.svg";
import whyImage from "@assets/why-img.png";

const Why = () => {
  const items = [
    {
      title: "Track Action Items With Ease",
      description:
        "Automatically generates action items from meeting notes, ensuring nothing gets missed.",
      icon: trackIcon,
    },
    {
      title: "Visual Progress Monitoring",
      description:
        "With a built-in progress bar, you can quickly see how much has been completed and what’s left. ",
      icon: progressIcon,
    },
    {
      title: "Easy, Simple and Time Saving",
      description:
        "By ensuring action items are completed before scheduling the next meeting, you save time.",
      icon: timeIcon,
    },
  ];

  return (
    <div className="px-14 py-16 sm:py-20" id="features">
      <div className="max-w-[1244px] mx-auto flex flex-col items-center gap-x-20 gap-y-4 sm:gap-y-10 lg:gap-y-14 2xl:gap-36">
        <div className="text-center">
          <h2 className="mb-4 text-[#805AD5] text-lg sm:text-[20px] font-semibold text-center tracking-[-2%]">
            WHY USE BEFOREWEMEET
          </h2>
          <p className="mb-8 text-[#040815] text-4xl sm:text-5xl font-bold text-center tracking-[-3%]">
            Save Time and Boost Accountability
          </p>
          <p className="mb-16 text-[#596780] text-lg sm:text-[20px] text-center tracking-[-2%]">
            Managing meetings effectively requires more than just scheduling.
            It’s about ensuring that every action point is tracked, completed,
            and contributes to the next steps. We simplifies this process,
            helping you ensure meetings stay productive.
          </p>
        </div>

        <div className="flex flex-col lg:flex-row-reverse items-center gap-[72px] sm:gap-20 lg:gap-24">
          <div className="lg:w-[48%] flex flex-col flex-shrink-0 gap-10 sm:gap-12 lg:gap-14">
            {items.map((card) => (
              <WhyCard key={card.title} {...card} />
            ))}
          </div>
          <div className="lg:w-[52%]">
            <img src={whyImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

const WhyCard = ({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: string;
}) => {
  return (
    <div className="flex gap-8">
      <div className="h-max mt-1 p-2.5 sm:p-[13px] flex-center flex-shrink-0 rounded-xl border border-primary">
        <img className="w-4 sm:w-6" src={icon} alt={""} />
      </div>
      <div>
        <h3 className="text-xl sm:text-2xl text-[#040815] font-semibold tracking-[-3%]">
          {title}
        </h3>
        <p className="text-sm sm:text-base text-[#596780] tracking-[-2%]">
          {description}
        </p>
      </div>
    </div>
  );
};

export default Why;
