import { appName } from "@utils/index";
// https://github.com/akiran/react-slick/issues/1837#issuecomment-1028174830
// import Slider from "@ant-design/react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import "./scroll.css";

const Reviews = () => {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 1000,
  //   autoplaySpeed: 3500,
  //   pauseOnHover: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 700,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 1,
  //       },
  //     },
  //   ],
  // };

  const reviews = [
    {
      name: "Mark R., Product Manager",
      text: `"My team engagement scores increased by 47% after implementing this system"`,
      img: "https://i.pravatar.cc/150?u=Demond_Robel72@hotmail.com",
      rating: 4,
    },
    // {
    //   name: "Sarah R.",
    //   text: `"${appName} has been a game-changer for our organization. It transformed the way we approach grant writing, saving us countless hours and helping us secure funding we never thought possible."`,
    //   img: "https://i.pravatar.cc/150?u=Florencio.Farrell6@yahoo.com",
    //   rating: 5,
    // },
    // {
    //   name: "Mason A.",
    //   text: `"${appName} is the secret weapon every grant-seeking team needs. It's like having an entire research team and writing coach in one. Our proposals have become more competitive and compelling thanks to this incredible tool."`,
    //   img: "https://i.pravatar.cc/150?u=Demond_Robel72@hotmail.com",
    //   rating: 4,
    // },
  ];

  return (
    <div className="py-12 text-center bg-primary-light">
      {/* <h2 className="mb-3 text-5xl font-bold">What Users Say About Us.</h2>
      <p className="mb-10 text-gray-500">Testimonials</p> */}
      <div className="flex gap-8 overflow-x-hidden justify-center -sm:flex-wrap">
        {/* <div className="-sm:w-[400%] sm:w-[200%] h-20 relative">
          <div className="-sm:w-[400%] sm:w-[200%] flex items-center h-20 justify-around absolute left-0 animate gap-20 animate"> */}
        {reviews?.map((review, index) => (
          <div
            key={index}
            // className="w-80 h-[300px] py-5 flex-center-y flex-col flex-shrink-0 border-4 border-secondary-dark rounded-[22px]"
            className="-sm:w-80 w-[25rem] h-[300px] py-5 flex-center-y flex-col flex-shrink-0"
          >
            <img
              className="h-28 w-28 mb-2 mx-auto rounded-full bg-primary-dark"
              src={review.img}
              alt=""
            />
            <p className="mb-2 text-[#3E005B]">{review.name}</p>
            {/* <div className="h-5 mb-3 bg-primary-dark w-1/2"></div> */}
            {/* <div className="h-5 flex-center mb-3">
              {[...Array(5)].map((_, index) => {
                return (
                  <div
                    key={index}
                    className={`text-xl leading-none mx-[3px] ${
                      index < review.rating
                        ? "text-primary-dark"
                        : "text-[#D0D5DD]"
                    }`}
                  >
                    &#9733;
                  </div>
                );
              })}
            </div> */}
            <p className="-sm:text-xl text-3xl text-gray-700">{review.text} </p>
          </div>
        ))}
        {/* </div>
        </div> */}
      </div>

      {/* <div className="">
        <Slider {...settings}>
          {reviews?.map((review, index) => {
            return (
              <div key={index}>
                <div className="w-80 h-[310px] mx-auto px-3 py-5 flex-center-y flex-col flex-shrink-0 border-4 border-secondary-dark rounded-[22px]">
                  <img
                    className="h-28 w-28 mb-2.5 mx-auto rounded-full bg-primary-dark"
                    src={review.img}
                    alt=""
                  />
                  <p className="mb-2 font-bold text-[#3E005B]">{review.name}</p>
                  <div className="h-5 flex-center mb-3">
                    {[...Array(5)].map((_, index) => {
                      return (
                        <div
                          key={index}
                          className={`text-xl leading-none mx-[3px] ${index < review.rating
                              ? "text-primary-dark"
                              : "text-[#D0D5DD]"
                            }`}
                        >
                          &#9733;
                        </div>
                      );
                    })}
                  </div>
                  <p className="text-xs text-gray-700 line-clamp-5">
                    {review.text}
                  </p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div> */}
    </div>
  );
};

export default Reviews;
