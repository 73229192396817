import actionIcon from "@/assets/action-icon.svg";
import clockIcon from "@/assets/clock-icon.svg";
import timelineIcon from "@/assets/timeline-icon.svg";
import calendarIcon from "@/assets/calendar-icon.svg";
import metricsIcon from "@/assets/metrics-icon.svg";

const Benefits = () => {
  const benefits = [
    {
      title: "Action Point Tracking",
      description: "Captures key tasks to keep everyone on track effortlessly",
      icon: actionIcon,
    },
    {
      title: "Progress Tracking",
      description:
        "Progress bar to help you monitor your progress in real-time",
      icon: clockIcon,
    },
    {
      title: "Customizable Timelines",
      description: "Add or edit action items with specific deadlines.",
      icon: timelineIcon,
    },
    {
      title: "Calendar Integration",
      description:
        "Keep track of action deadlines with our built-in calendar view",
      icon: calendarIcon,
    },
    {
      title: "Metrics",
      description:
        "With clear metrics, you can quickly assess readiness for the next meeting.",
      icon: metricsIcon,
    },
  ];

  return (
    <div className="py-20" id="benefits">
      <h2 className="mb-2 text-[#805AD5] text-lg sm:text-[20px] font-semibold text-center tracking-[-2%]">
        BENEFITS
      </h2>
      <p className="mb-20 sm:mb-[106px] text-[#040815] text-4xl sm:text-5xl font-bold text-center tracking-[-3%]">
        Features We Provide For You
      </p>

      <div className="flex flex-wrap justify-center gap-x-10 gap-y-11 sm:gap-y-16">
        {benefits.map((card) => (
          <BenefitCard key={card.title} {...card} />
        ))}
      </div>
    </div>
  );
};

const BenefitCard = ({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: string;
}) => {
  return (
    <div className="max-w-[398px] px-4 py-6 flex items-center gap-[19px] border border-[#EFEFEF] rounded-2xl shadow-[4px_8px_4px_1px_rgba(151,151,151,0.05)]">
      <div className="p-1.5 sm:p-2 flex-center bg-[#60A5FA] rounded-full">
        <img className="w-8 sm:w-10 flex-shrink-0" src={icon} alt={""} />
      </div>
      <div>
        <h3 className="mb-2 text-[#212B36] text-base sm:text-lg font-semibold">
          {title}
        </h3>
        <p className="text-[#637381] text-sm sm:text-16">{description}</p>
      </div>
    </div>
  );
};

export default Benefits;
