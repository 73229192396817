import { appName } from "@utils/index";
import Banner from "@components/Banner";
import SEO from "@components/SEO";
import SSRTest from "@components/ssrTest/SSRTest";
import Footer from "./Footer";
import Landing from "./Landing";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";

const Index = () => {
  return (
    <>
      {/* <SSRTest /> */}
      <Banner
        showOffer={false}
        text={<p className="">Get 50% off when you signup for early access</p>}
      />
      <Navbar />
      {/* <SEO /> */}
      <Outlet />
      <Footer />
    </>
  );
};

export default Index;
