import { BsArrowRight, BsRocketTakeoff } from "react-icons/bs/index";
import Pricing from "./Pricing";
import Faqs from "./FAQ";
import appSnip from "@assets/appSnip.png";
import startBg from "@assets/start-bg.svg";
import Why from "./Why";
import Benefits from "./Benefits";
import readingPerson from "@assets/reading-person.png";
import metricsImage from "@assets/metrics-image.png";
import flowImage1 from "@assets/flow-img-1.png";
import flowImage2 from "@assets/flow-img-2.png";
import flowImage3 from "@assets/flow-img-3.png";
import stepLink from "@assets/step-link.svg";

const Landing = () => {
  return (
    <div className="overflow-hidden">
      <div
        id="home"
        className="px-10 sm:px-14 pt-10 relative text-center bg-[#EFF6FF] overflow-hidden"
      >
        <div className="max-w-[907px] mx-auto mb-16 px-2 sm:px-5">
          <p className="mb-6 pt-14 sm:pt-[72px] lg:text-7xl md:text-[64px] sm:text-[52px] text-[46px] text-center font-bold leading-tight">
            <span className="gradient-text">Reclaim your time back </span>
            from unproductive
            <span className="whitespace-nowrap">1-1s</span>
          </p>
          <p className="max-w-[1000px] mx-auto mb-12 sm:mb-16 md:mb-20 text-[#344054]">
            Make every meeting count by turning discussions into actionable
            steps! With clear, trackable action items, you’ll keep momentum
            going and ensure goals are met.
          </p>
          <div className="w-full flex-center flex-col sm:flex-row gap-6 text-[18px] -sm:text-base text-center">
            <button
              onClick={() => window.open("#pricing", "_self")}
              className=" py-4 px-6 sm:px-10 md:px-14 flex-center-y text-base sm:text-lg text-white font-bold leading-none bg-primary rounded-full"
            >
              Contact Sales
            </button>
            <button
              onClick={() => window.open("#pricing", "_self")}
              className="py-4 px-6 flex-center-y text-base sm:text-lg text-primary font-bold leading-none bg-white border border-primary rounded-full"
            >
              7 days free trial
            </button>
          </div>
        </div>

        <div className="w-full max-w-[907px] mx-auto">
          <img className="w-full" src={appSnip} alt="" />
        </div>
      </div>

      <Why />

      <Benefits />

      <div className="px-10 sm:px-14 py-20 bg-[#EFF6FF]">
        <div className="max-w-[1244px] mx-auto flex flex-col lg:flex-row-reverse items-center justify-between gap-x-20 gap-y-14 2xl:gap-36">
          <div>
            <p className="mb-4 text-lg sm:text-xl font-semibold text-[#805AD5]">
              Improve Meeting flow
            </p>
            <p className="mb-8 text-4xl sm:text-5xl font-bold">
              No unproductive meetings{" "}
            </p>
            <p className="mb-6 text-base sm:text-lg text-[#222222]">
              With real-time action item generation and clear progress metrics,
              you can eliminates the guesswork, and keeps everyone on the same
              page. Simplify post-meeting follow-ups, reduce time spent on
              redundant discussions, and focus on driving outcomes that make
              each meeting count
            </p>
            <button
              onClick={() => window.open("/#benefits", "_self")}
              className="text-lg sm:text-xl flex-center-y gap-2 text-primary font-bold"
            >
              <span>View all benefit</span>
              <BsArrowRight />
            </button>
          </div>

          <div className="max-w-[350px] sm:max-w-none sm:min-w-[350px] sm:w-[50%] lg:w-[55%] flex-shrink-0">
            <img src={metricsImage} alt="" />
          </div>
        </div>
      </div>

      <div className="px-10 sm:px-14 py-20">
        <div className="max-w-[1244px] mx-auto flex flex-col lg:flex-row items-center justify-between gap-x-20 gap-y-14 2xl:gap-36">
          <div>
            <p className="mb-4 text-lg sm:text-xl font-semibold text-[#805AD5]">
              Say goodbye to missed action items
            </p>
            <p className="mb-8 text-4xl sm:text-5xl font-bold">
              Level up your accountability game
            </p>
            <p className="mb-6 text-base sm:text-lg text-[#222222]">
              With clear action items and transparent progress tracking, knowing
              that the next meeting is based on the progress keeps everyone
              accountable. This approach empowers you to support one another and
              stay laser-focused on achieving your goals.{" "}
            </p>
            <button
              onClick={() => window.open("#how-it-works", "_self")}
              className="text-lg sm:text-xl flex-center-y gap-2 text-primary font-bold"
            >
              <span>How it Works</span>
              <BsArrowRight />
            </button>
          </div>

          <div className="max-w-[350px] sm:max-w-none sm:min-w-[350px] sm:w-[50%] lg:w-[55%] flex-shrink-0">
            <img src={readingPerson} alt="" />
          </div>
        </div>
      </div>

      <div className="px-10 sm:px-14 py-20 text-center" id="how-it-works">
        <div className="max-w-[1244px] mx-auto flex flex-col items-center gap-x-20 gap-y-14 2xl:gap-36">
          <div className="flex flex-col items-center">
            <p className="mb-4 text-lg sm:text-xl font-semibold text-[#805AD5]">
              HOW IT WORKS
            </p>
            <p className="mb-8 text-4xl sm:text-5xl font-bold">
              Few Easy Steps and Done
            </p>
            <p className="mb-6 text-base sm:text-lg text-[#222222]">
              In just a few simple steps, you're all set to manage your meeting
              action items. Keep your team on track and meetings productive with
              ease!
            </p>
          </div>

          <div className="w-full space-y-4">
            <div className="w-full flex flex-wrap justify-center gap-20">
              <div className="max-w-[276px] space-y-4">
                <div className="w-full aspect-[0.88] p-6 flex-center-y bg-[#fba09a] rounded-3xl">
                  <img src={flowImage1} alt="" />
                </div>
                <div className="space-y-2 ">
                  <h3 className="text-xl sm:text-2xl text-[#1A202C] font-semibold">
                    Upload Your Meeting Notes
                  </h3>
                  <p className="text-[12px] sm:text-sm text-[#4e5163]">
                    You can either manually enter your meeting notes or upload a
                    transcript from the discussion. The app will automatically
                    generate action items based on your input,
                  </p>
                </div>
              </div>

              <div className="max-w-[276px] space-y-4">
                <div className="w-full aspect-[0.88] p-6 flex-center-y bg-[#b794f4] rounded-3xl">
                  <img src={flowImage2} alt="" />
                </div>
                <div className="space-y-2">
                  <h3 className="text-xl sm:text-2xl text-[#1A202C] font-semibold">
                    Track Progress{" "}
                  </h3>
                  <p className="text-[12px] sm:text-sm text-[#4e5163]">
                    As items are worked on, you or your team can manually update
                    progress within the app. to provides a clear, visual way to
                    see how close each action item is to completion.
                  </p>
                </div>
              </div>

              <div className="max-w-[276px] space-y-4">
                <div className="w-full aspect-[0.88] p-6 flex-center-y bg-[#faa7e0] rounded-3xl">
                  <img src={flowImage3} alt="" />
                </div>
                <div className="space-y-2">
                  <h3 className="text-xl sm:text-2xl text-[#1A202C] font-semibold">
                    Make A Decision
                  </h3>
                  <p className="text-[12px] sm:text-sm text-[#4e5163]">
                    Once the action items are updated, you can easily assess
                    whether the next meeting is necessary or if additional work
                    is needed before scheduling another meeting.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Pricing />

      <Faqs />

      <div className="px-10 sm:px-14 py-20 text-white relative">
        <img
          className="h-full w-full object-left object-cover absolute -z-10 top-0 left-0"
          src={startBg}
          alt=""
        />
        <div className="max-w-[1000px] mx-auto z-10 flex flex-col justify-center items-center gap-8">
          <p className=" text-center text-[44px] sm:text-[48px] lg:text-[56px] leading-tight font-bold">
            Start having better meetings today
          </p>
          <p className="max-w-[750px] text-center text-base sm:text-lg">
            Make every meeting count by turning discussions into actionable
            steps! With clear, trackable action items, you’ll keep momentum
            going and ensure goals are met
          </p>
          <a
            href="/#pricing"
            className="px-10 sm:px-12 py-3.5 sm:py-5 text-xl sm:text-2xl font-bold leading-none bg-gradient-2 rounded-full"
          >
            Start For Free
          </a>
        </div>
      </div>
    </div>
  );
};

interface GSDefault {
  className?: string;
  showIcon?: boolean;
  mxAuto?: boolean;
  variant?: boolean;
}

// type GSOptional =  {
//   showIcon?: never;
//   mxAuto?: never;
//   variant?: boolean;
// } | {
//   showIcon?: boolean;
//   mxAuto?: boolean;
//   variant: boolean;
// }

// type Props = GSDefault & GSOptional;

export const GetStarted = ({
  showIcon = true,
  mxAuto = true,
  variant = false,
  className: cN,
}: GSDefault) => {
  return (
    <button
      onClick={() => window.open("#pricing", "_self")}
      className={`${mxAuto ? "mx-auto" : ""} -sm:p-3 sm:p-3.5 sm:px-8 ${
        variant
          ? "text-primary bg-white"
          : " flex-center-y text-white bg-primary"
      } rounded-md ${cN ? cN : ""}`}
    >
      {showIcon ? <BsRocketTakeoff /> : null}
      <span className={`${showIcon ? "ml-2" : ""}`}>Get Started</span>
    </button>
  );
};

export default Landing;
