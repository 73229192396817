import React from "react";
import toggleIcon from "@assets/toggle-icon.svg";
import darkCheckIcon from "@assets/dark-checkmark-icon.svg";
import lightCheckIcon from "@assets/light-checkmark-icon.svg";
import { useDisclosure } from "@chakra-ui/react";
import OverlayModal from "@components/overlay/OverlayModal";
import { useState } from "react";

const Pricing = () => {
  const [showNotice, setShowNotice] = useState(false);

  return (
    <>
      {showNotice ? (
        <OverlayModal
          header={"Announcement 📢"}
          title={"We're coming soon"}
          onClose={() => setShowNotice(false)}
        >
          <div className="flex flex-col items-center">
            <p className="text-center">
              We'll notify you when we're ready to onboard new users.
            </p>
            <button
              onClick={() => {
                window.open(
                  "https://dashboard.mailerlite.com/forms/1171645/136846710837085259/share",
                  "_blank"
                );
                setShowNotice(false);
              }}
              className="mt-5 bg-primary text-white px-6 py-2 rounded-lg"
            >
              Join The Waitlist To Get Notified
            </button>
          </div>
        </OverlayModal>
      ) : null}

      <div
        id="pricing"
        className="px-10 sm:px-14 py-[60px] flex-center-y flex-col text-gray-900 bg-[#EFF6FF]"
      >
        <p className="mb-1 sm:mb-2 text-base sm:text-lg text-[#805ad5] font-bold uppercase tracking-[1px]">
          Ready to get started
        </p>
        <h2 className="mb-3 sm:mb-5 text-[40px] sm:text-5xl text-[#322659] font-bold tracking-[-2%]">
          Our Pricing
        </h2>
        <p className="mb-8 sm:mb-10 text-center text-[#98a2b3]">
          We have customized plans that fits your needs.Start with a 7-days
          trial and cancel anytime you want.
        </p>

        <div className="mb-7 sm:mb-9 flex gap-3">
          <img src={toggleIcon} alt="" />
          <p>
            Annual pricing
            <span className="text-primary"> (save 20%)</span>
          </p>
        </div>

        <div className="mb-6 flex flex-wrap justify-center gap-5">
          <PricingCard
            title="Basic"
            price="$10"
            period="/month"
            description="Essentials for Solo Users and Small Teams"
            features={[
              "Action point generation",
              "Progress tracking bar",
              "Manual updates",
              "5 action items per meeting",
              "Up to 3 team members",
            ]}
            buttonText="Get Started"
            onButtonClick={() => setShowNotice(true)}
            colorScheme="light"
          />

          <PricingCard
            title="Professional"
            price="$20"
            period="/month"
            description="Enhanced Tools for professionals to help their meetings"
            features={[
              "Everything on Basic plan",
              "Unlimited action items",
              "Task assignment",
              "Notifications for deadlines",
              "Up to 10 team members",
            ]}
            buttonText="Start Now"
            onButtonClick={() => setShowNotice(true)}
            colorScheme="dark"
            popular
          />

          <PricingCard
            title="Enterprise"
            price="$30"
            period="/month"
            description="Complete Control to host more than 1 account."
            features={[
              "Everything on Pro plan",
              "Custom integrations",
              "Manual updates",
              "Unlimited action items",
              "Up to 50 team members",
            ]}
            buttonText="Get Started"
            onButtonClick={() => setShowNotice(true)}
            colorScheme="light"
          />
        </div>
      </div>
    </>
  );
};

const PricingItem = ({
  title,
  variant,
  children,
}: {
  title: string;
  variant: "light" | "dark";
  children?: React.ReactNode;
}) => {
  return (
    <li className="h-max my-3 sm:my-4 flex gap-2.5 -sm:gap-2">
      <img
        className="w-5 -sm:w-4 h-5 -sm:h-4 mt-1"
        src={variant === "dark" ? lightCheckIcon : darkCheckIcon}
        alt={title}
      />
      <span
        className={`${variant === "dark" ? "text-white" : "text-[#475467]"}`}
      >
        {title}
      </span>
      {children ? children : null}
    </li>
  );
};

type PricingCardProps = {
  title: string;
  price: string;
  period: string;
  description: string;
  features: string[];
  colorScheme?: "light" | "dark";
  buttonText: string;
  onButtonClick: () => void;
  popular?: boolean;
};

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  period,
  description,
  features,
  colorScheme = "light",
  buttonText,
  onButtonClick,
  popular = false,
}) => (
  <div
    className={`py-10 sm:py-12 px-6 sm:px-10 w-full max-w-[388px] rounded-[14px] ${
      colorScheme === "light"
        ? "bg-white text-[#475467] border border-[#D4D2E3]"
        : "bg-primary text-white"
    }`}
  >
    <div className="flex flex-col gap-1.5">
      <div className="flex justify-between items-center">
        <h3 className="text-xl sm:text-2xl leading-[38px] font-bold">
          {title}
        </h3>
        {popular && (
          <span className="px-4 sm:px-[22px] py-2.5 sm:py-3.5 text-base sm:text-lg text-[#1E285F] bg-white rounded-full">
            Popular
          </span>
        )}
      </div>
      <div className={`${popular ? "-mt-1" : "mt-0"}`}>
        <span className="mr-2 text-[52px] sm:text-6xl font-bold leading-none">
          {price}
        </span>
        <span className="text-lg sm:text-xl font-medium">{period}</span>
      </div>
      <p className="-mt-1 text-base sm:text-lg">{description}</p>
    </div>
    <div className="h-[1px] w-full bg-[#D4D2E3] my-8 sm:my-10" />
    <ul className="text-base sm:text-lg text-left font-medium">
      {features.map((feature, index) => (
        <PricingItem key={index} title={feature} variant={colorScheme} />
      ))}
    </ul>
    <button
      onClick={onButtonClick}
      className={`w-full mt-10 sm:my-8 p-3.5 sm:p-3 text-base sm:text-lg font-bold ${
        colorScheme === "light"
          ? "bg-primary hover:bg-primary/80 text-white"
          : "bg-white hover:bg-gray-100 text-primary"
      } rounded-full`}
    >
      {buttonText}
    </button>
  </div>
);

export default Pricing;
